@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/Rubik/Rubik-Light.ttf") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/Rubik/Rubik-Regular.ttf") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/Rubik/Rubik-Medium.ttf") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  src: url("/assets/fonts/Rubik/Rubik-SemiBold.ttf") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/Rubik/Rubik-Bold.ttf") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: bold;
  src: url("/assets/fonts/Rubik/Rubik-Bold.ttf") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 900;
  src: url("/assets/fonts/Rubik/Rubik-ExtraBold.ttf") format("woff");
  font-display: swap;
}
